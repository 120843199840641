import Grider from '../datagrid/Grider';
export default class FreeTableGrider extends Grider {
    constructor(modelState, dispatchModel) {
        super();
        this.modelState = modelState;
        this.dispatchModel = dispatchModel;
        this.model = modelState && modelState.value;
    }
    getRowData(index) {
        var _a;
        return (_a = this.model.rows) === null || _a === void 0 ? void 0 : _a[index];
    }
    get rowCount() {
        var _a;
        return (_a = this.model.rows) === null || _a === void 0 ? void 0 : _a.length;
    }
    get currentModel() {
        return this.batchModel || this.model;
    }
    set currentModel(value) {
        if (this.batchModel)
            this.batchModel = value;
        else
            this.dispatchModel({ type: 'set', value });
    }
    setCellValue(index, uniqueName, value) {
        const model = this.currentModel;
        if (model.rows[index]) {
            this.currentModel = Object.assign(Object.assign({}, model), { rows: model.rows.map((row, i) => (index == i ? Object.assign(Object.assign({}, row), { [uniqueName]: value }) : row)) });
        }
    }
    setRowData(index, document) {
        const model = this.currentModel;
        if (model.rows[index]) {
            this.currentModel = Object.assign(Object.assign({}, model), { rows: model.rows.map((row, i) => (index == i ? document : row)) });
        }
    }
    get editable() {
        return true;
    }
    get canInsert() {
        return true;
    }
    get allowSave() {
        return true;
    }
    insertRow() {
        const model = this.currentModel;
        this.currentModel = Object.assign(Object.assign({}, model), { rows: [...model.rows, {}] });
        return this.currentModel.rows.length - 1;
    }
    insertDocuments(documents) {
        const model = this.currentModel;
        this.currentModel = Object.assign(Object.assign({}, model), { rows: [...model.rows, ...documents] });
        return this.currentModel.rows.length - documents.length;
    }
    deleteRow(index) {
        const model = this.currentModel;
        this.currentModel = Object.assign(Object.assign({}, model), { rows: model.rows.filter((row, i) => index != i) });
    }
    beginUpdate() {
        this.batchModel = this.model;
    }
    endUpdate() {
        if (this.model != this.batchModel) {
            this.dispatchModel({ type: 'set', value: this.batchModel });
            this.batchModel = null;
        }
    }
    // static factory({ modelState, dispatchModel }): FreeTableGrider {
    //   return new FreeTableGrider(modelState, dispatchModel);
    // }
    // static factoryDeps({ modelState, dispatchModel }) {
    //   return [modelState, dispatchModel];
    // }
    undo() {
        this.dispatchModel({ type: 'undo' });
    }
    redo() {
        this.dispatchModel({ type: 'redo' });
    }
    get canUndo() {
        return this.modelState.canUndo;
    }
    get canRedo() {
        return this.modelState.canRedo;
    }
}
