import { __rest } from "tslib";
import _ from 'lodash';
import { get } from 'svelte/store';
import { currentDatabase } from '../stores';
import getConnectionLabel from '../utility/getConnectionLabel';
import openNewTab from '../utility/openNewTab';
export default function newQuery(_a = {}) {
    var { tabComponent = 'QueryTab', icon = 'img sql-file', title = undefined, initialData = undefined } = _a, props = __rest(_a, ["tabComponent", "icon", "title", "initialData"]);
    const $currentDatabase = get(currentDatabase);
    const connection = _.get($currentDatabase, 'connection') || {};
    const database = _.get($currentDatabase, 'name');
    const tooltip = `${getConnectionLabel(connection)}\n${database}`;
    openNewTab({
        title: title || 'Query #',
        icon,
        tooltip,
        tabComponent,
        props: Object.assign(Object.assign({}, props), { conid: connection._id, database }),
    }, { editor: initialData });
}
export function newQueryDesign() {
    return newQuery({ tabComponent: 'QueryDesignTab', icon: 'img query-design' });
}
export function newDiagram() {
    return newQuery({ tabComponent: 'DiagramTab', icon: 'img diagram', title: 'Diagram #' });
}
export function newPerspective() {
    return newQuery({ tabComponent: 'PerspectiveTab', icon: 'img perspective', title: 'Perspective #' });
}
